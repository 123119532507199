#map-kz {
    height: 250px;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
  }
  
  #map-ru {
    height: 250px;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
  }
  
  #map-spb {
    height: 250px;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
  }